/* eslint-disable no-param-reassign */
import Handsontable from "handsontable";
import ReactDOM from "react-dom";
import React from "react";
import { Socket } from "socket.io-client";
import { ReactComponent as ApproveIcon } from "../../../../IA/svgs/approve.svg";
import { ReactComponent as ApproveIconActive } from "../../../../IA/svgs/approveActive.svg";
import { ReactComponent as DisapproveIcon } from "../../../../IA/svgs/disapprove.svg";
import { ReactComponent as DisapproveIconActive } from "../../../../IA/svgs/disapproveActive.svg";
import { IProductToTable } from "../../../../../context/products/product.context";

export function customDescriptionIAFunction(
  instance: Handsontable,
  td: HTMLTableCellElement,
  row: number,
  col: number,
  prop: string | number,
  value: any,
  products: IProductToTable[],
  updateIdList: ({
    id,
    listType,
  }: {
    id: string;
    listType: "approved" | "disapproved";
  }) => void,
  approvedIdsList: string[],
  disapprovedIdsList: string[],
  socketRef: React.MutableRefObject<Socket | null>,
  templateId: string,
  approveAll: boolean,
  disapproveAll: boolean,
): void {
  const wrapper = document.createElement("div");
  wrapper.style.width = "100%";
  wrapper.style.height = "100%";
  wrapper.style.paddingTop = "6px";

  const container = document.createElement("div");
  container.style.width = "calc(100% - 6px)";
  container.style.height = "40px";
  container.style.display = "flex";
  container.style.alignItems = "center";
  container.style.justifyContent = "space-between";
  container.style.maxHeight = "52px";

  wrapper.appendChild(container);

  td.innerHTML = "";

  const textContainer = document.createElement("div");
  textContainer.style.flex = "1";
  textContainer.style.paddingLeft = "6px";
  textContainer.style.textAlign = "left";
  textContainer.style.width = "70%";
  textContainer.style.overflow = "hidden";
  textContainer.style.whiteSpace = "normal";
  textContainer.style.alignSelf = "flex-start";
  textContainer.style.cursor = "pointer";
  textContainer.innerHTML = value;

  container.appendChild(textContainer);

  const buttonContainer = document.createElement("div");
  buttonContainer.style.display = "flex";
  buttonContainer.style.height = "100%";
  buttonContainer.style.alignItems = "center";
  buttonContainer.style.justifyContent = "flex-end";

  const productId = products[row].id;

  const approveButton = document.createElement("button");
  approveButton.style.border = "none";
  approveButton.style.background = "none";
  approveButton.style.marginRight = "5px";

  const approveIconContainer = document.createElement("div");
  approveIconContainer.style.width = "32px";
  approveIconContainer.style.height = "32px";
  ReactDOM.render(
    approvedIdsList.includes(productId) ? (
      <ApproveIconActive />
    ) : (
      <ApproveIcon />
    ),
    approveIconContainer,
  );
  if (value?.length > 0) {
    approveButton.appendChild(approveIconContainer);
  }

  const disapproveButton = document.createElement("button");
  disapproveButton.style.border = "none";
  disapproveButton.style.background = "none";
  disapproveButton.style.marginLeft = "5px";

  const disapproveIconContainer = document.createElement("div");
  disapproveIconContainer.style.width = "32px";
  disapproveIconContainer.style.height = "32px";
  ReactDOM.render(
    disapprovedIdsList.includes(productId) ? (
      <DisapproveIconActive />
    ) : (
      <DisapproveIcon />
    ),
    disapproveIconContainer,
  );
  if (value?.length > 0) {
    disapproveButton.appendChild(disapproveIconContainer);
  }

  approveButton.addEventListener("click", () => {
    updateIdList({ id: productId, listType: "approved" });
    if (!approvedIdsList.includes(productId)) {
      socketRef?.current?.emit("ai_approve_description", {
        productId,
        templateId,
      });
    } else {
      socketRef?.current?.emit("ai_unapprove_description", {
        productId,
        templateId,
      });
    }
  });

  disapproveButton.addEventListener("click", () => {
    updateIdList({ id: productId, listType: "disapproved" });
    if (!disapprovedIdsList.includes(productId)) {
      socketRef?.current?.emit("ai_unapprove_description", {
        productId,
        templateId,
      });
    }
  });

  buttonContainer.appendChild(approveButton);
  buttonContainer.appendChild(disapproveButton);

  container.appendChild(buttonContainer);
  td.appendChild(wrapper);

  textContainer.addEventListener("click", () => {
    const contentHeight = textContainer.scrollHeight;
    const rowHeight = instance.getRowHeight(row);

    instance.updateSettings({
      rowHeights: (r) => {
        if (r === row && contentHeight > 52 && rowHeight <= 52) {
          return contentHeight + 16;
        }

        return 52;
      },
    });
  });
}

export default customDescriptionIAFunction;
