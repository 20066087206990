import { renderToString } from "react-dom/server";
import { ReactComponent as DropDownIconSmall } from "../../../../../assets/chevron-down-small.svg";
import logoShop from "../../../../../assets/images/logoShop.png";
import logoNexaas from "../../../../../assets/images/logoNexass2.png";

const svgStringDropDownSmall: string = renderToString(<DropDownIconSmall />);

const BASE_STYLES = `
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 51px;
  padding: 0 1.5rem;
  font-family: 'Satoshi Regular';
  position: relative;
  `;
const PLUS_BASE_STYLES = `
  display: flex;
  align-items: center;
  justify-content: center;
  height: 51px;
  padding: 0 1.5rem;
  font-family: 'Satoshi Regular';
  `;

const TEXT_STYLE = `
  font-size: 14px;
  color: rgb(134, 142, 150);
  margin: 0;
  text-align: center;
  `;
const PLUS_STYLE = `
  font-size: 30px;
  color: rgb(134, 142, 150);
  margin: 0;
  text-align: center;
  `;

const REQUIRED_STYLE = `
  border: 1px solid rgb(255, 0, 0);
  border-radius: 20px;
  color: rgb(255, 0, 0);
  padding-inline: 7px;
  margin: 0;
  font-size: 12px;
`;

const FLEX_GAP_STYLE = `
  display: flex;
  align-items: center;
  gap: 10px;
`;

const SVG_STYLE = `
  cursor: pointer;
  border: none;
  background: none;
`;

const ICON_STYLE = `
  display: flex;
  align-items: center;
  pointer-events: none;
`;
const REQUIRED_INTEGRATION = `
  height: 32px;
  min-width: 32px;
  background: white;
  border-radius: 50px;
  display: flex;
  gap: 4px;
  padding: 4px;
  align-items: center;
  position: relative;
  z-index: 999999999999999999999999999;
`;
const MORE_REQUIRED_INTEGRATION = `
  height: 24px;
  width: 24px;
  background: #FF6B6B;
  color:#FFFFFF;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const REQUIRED_INTEGRATION_BUTTON = `
  display: flex;
  border: none;
  background: none;
  padding: 0;
`;
const CHECKBOX_STYLE = `
 height: 100%;
`;

const COLORGROUP = `
 width: 100%;
 height: 2px;
 position: absolute;
 top: 0;
 left: 0;
`;
const CONTAINER_IA = `
 display: flex;
 align-items: center;
 gap: 8px;
 height: 33px !important;
 z-index:10;
 `;
const CONTAINER_BUTTON_IA = `
 display: flex;
 align-items: center;
 gap: 8px;

 `;
const TEXT_IA = `
 padding: 0;
 display: flex;
 margin: 0;
 align-items: center;
`;
const BUTTON_IA = `
 padding: 0;
 margin: 0;
 height: 33px;
 border: none;
 background: none;
`;

const getStyledContent = (
  approveAll: boolean,
  setApproveAll: React.Dispatch<React.SetStateAction<boolean>>,
  disapproveAll: boolean,
  setDisapproveAll: React.Dispatch<React.SetStateAction<boolean>>,
  iconType: any,
  valueToVisible: string | number | undefined,
  isRequired: boolean,
  colData: any,
  editModeGroup: "group" | "ungroup" | "",
  idsColumnsSelecteds: string[],
  groupReferenceEditMode: string,
  changeAllRowsSelected: () => void,
  groups: {
    label: string;
    total: number;
    color: string;
  }[],
  allRowsSelected?: boolean,
  isPublic?: boolean,
): string => {
  (window as any).changeAllRowsSelected = changeAllRowsSelected;

  if (valueToVisible === "checkPublic") {
    return `<input type='checkbox' style='${CHECKBOX_STYLE}' onclick="window.changeAllRowsSelected()" ${
      allRowsSelected ? "checked" : ""
    }/>`;
  }
  const selectedGroup = groups.filter((item) => {
    return item.label === colData?.group;
  })[0];

  const integrationsList = colData?.integrations;
  const moreNumber = integrationsList - 1;
  const colSelected = idsColumnsSelecteds.includes(colData?.data);

  return `
    <div style="${valueToVisible !== "+" ? BASE_STYLES : PLUS_BASE_STYLES}">
    <div style="${COLORGROUP}; background:${selectedGroup?.color};"></div>
     ${
       valueToVisible !== "+" &&
       editModeGroup &&
       valueToVisible &&
       ((!colData?.group && editModeGroup === "group") ||
         (editModeGroup === "ungroup" &&
           (colData.group === groupReferenceEditMode || !colData.group)))
         ? `<input class='checkGroup' type="checkbox" ${
             colSelected ? "checked" : ""
           } />`
         : ""
     }
      <div style="${FLEX_GAP_STYLE}">
        ${renderToString(iconType)}
        <p style="${
          valueToVisible !== "+" ? TEXT_STYLE : PLUS_STYLE
        }">${valueToVisible}</p>
      </div>
      <div style="${FLEX_GAP_STYLE}">
        ${isRequired ? `<p style="${REQUIRED_STYLE}">Obrigatório</p>` : ""}
        ${
          colData?.type !== "ia" &&
          valueToVisible &&
          !isPublic &&
          valueToVisible !== "+"
            ? `<button style="${SVG_STYLE}" class="dropDown">
                <div style="${ICON_STYLE}">${svgStringDropDownSmall}</div>
              </button>`
            : ""
        }
        ${
          integrationsList?.length
            ? `<div style="${REQUIRED_INTEGRATION}" >
          <button style="${REQUIRED_INTEGRATION_BUTTON}" class="REQUIRED_INTEGRATION_BUTTON">
            <svg class="REQUIRED_INTEGRATION_BUTTON" xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
              <path d="M10 18C10.9849 18 11.9602 17.806 12.8701 17.4291C13.7801 17.0522 14.6069 16.4997 15.3033 15.8033C15.9997 15.1069 16.5522 14.2801 16.9291 13.3701C17.306 12.4602 17.5 11.4849 17.5 10.5C17.5 9.51509 17.306 8.53982 16.9291 7.62987C16.5522 6.71993 15.9997 5.89314 15.3033 5.1967C14.6069 4.50026 13.7801 3.94781 12.8701 3.5709C11.9602 3.19399 10.9849 3 10 3C8.01088 3 6.10322 3.79018 4.6967 5.1967C3.29018 6.60322 2.5 8.51088 2.5 10.5C2.5 12.4891 3.29018 14.3968 4.6967 15.8033C6.10322 17.2098 8.01088 18 10 18Z" stroke="#FF6B6B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10 7.16675H10.0083" stroke="#FF6B6B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M9.16797 10.5H10.0013V13.8333H10.8346" stroke="#FF6B6B" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
         ${
           integrationsList[0].provider === "nexaas"
             ? `<img src="${logoNexaas}" />`
             : `<img src="${logoShop}" />`
         }

          ${
            moreNumber
              ? `<div  style="${MORE_REQUIRED_INTEGRATION}">+${moreNumber}</div>`
              : ""
          }

        </div>`
            : ""
        }
         ${
           colData?.type === "ia"
             ? `<div style="${CONTAINER_IA}">
                  <p style="${TEXT_IA}">Revisar todos:</p>
                  <div style="${CONTAINER_BUTTON_IA}">
                    <button type="button" style="${BUTTON_IA}" class="approveButtonIA">
                     ${
                       approveAll
                         ? `<svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="0.5" width="32" height="32" rx="16" fill="#20C997"/>
                            <path d="M11.332 16.5013L14.6654 19.8346L21.332 13.168" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>`
                         : `<svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="1" width="31" height="31" rx="15.5" fill="white"/>
                            <rect x="0.5" y="1" width="31" height="31" rx="15.5" stroke="#DEE2E6"/>
                            <path d="M11.332 16.5013L14.6654 19.8346L21.332 13.168" stroke="#20C997" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>`
                     }
                    </button>
                    <button type="button" style="${BUTTON_IA}" class="disapproveButtonIA">
                     ${
                       disapproveAll
                         ? `<svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect y="0.5" width="32" height="32" rx="16" fill="#EF5533"/>
                            <path d="M20 12.5L12 20.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 12.5L20 20.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            `
                         : `<svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="0.5" y="1" width="31" height="31" rx="15.5" fill="white"/>
                            <rect x="0.5" y="1" width="31" height="31" rx="15.5" stroke="#DEE2E6"/>
                            <path d="M20 12.5L12 20.5" stroke="#EF5533" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M12 12.5L20 20.5" stroke="#EF5533" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            `
                     }
                    </button>
                  </div>
                </div>`
             : ""
         }
      </div>
    </div>

  `;
};

export default getStyledContent;
