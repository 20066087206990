/* eslint-disable import/prefer-default-export */
import { AxiosResponse } from "axios";
import { api } from "../api";
import { STORAGE } from "../../../constants/localStorage";

export const IARequests = {
  postEnrichment: async ({
    data,
  }: {
    data: {
      type: string;
      action: string;
      templateId: string;
      fields: string[];
      instructions: string;
      productsIds: string[];
    };
  }): Promise<AxiosResponse> => {
    const token = window.localStorage.getItem(STORAGE.TOKEN);

    const response = await api.post(`/products/enrichment`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "ngrok-skip-browser-warning": "69420",
      },
    });

    return response;
  },
  postFinishEnrichment: async ({
    data,
  }: {
    data: {
      templateId: string;
    };
  }): Promise<AxiosResponse> => {
    const token = window.localStorage.getItem(STORAGE.TOKEN);

    const response = await api.post(`/products/finish`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "ngrok-skip-browser-warning": "69420",
      },
    });

    return response;
  },
};
