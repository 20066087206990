const fixedOptions = [
  {
    value: "Criar nova coluna",
    label: "Criar nova coluna",
    openDropdown: true,
  },
  { value: "Ignorar", label: "Ignorar" },
];

export default fixedOptions;
