import React, { useEffect, useMemo, useState } from "react";
import Modal from "../../Modal";
import { ReactComponent as CloseIcon } from "../../../assets/close-gray.svg";
import {
  ButtonClose,
  ContainerButton,
  ContainerItems,
  ContentTop,
  Header,
  InputButtonWrapper,
  ItemButton,
  SectionTitle,
  Text,
  TextArea,
  TextSwitchWrapper,
  Title,
  WrapperCollectInfomation,
} from "./styles";
import DefaultInput from "../../DefaultInput";
import { NavigationButton } from "../../NavigationButton/styles";
import useDebounce from "../../../hooks/useDebounce/useDebounce";
import { useProductContext } from "../../../context/products";
import { SwitchCustom } from "../../RepDropdownMenu/styles";
import "./styles.css";
import { IProductToTable } from "../../../context/products/product.context";
import { ReactComponent as SearchIcon } from "./svgs/search.svg";

function CollectInformation({
  setModalInformationOpened,
  setSelecteds,
  selecteds,
  setInstructions,
  setLoadingIA,
  onFinish,
  setIAMode,
  setReviewMode,
}: {
  setModalInformationOpened: React.Dispatch<React.SetStateAction<boolean>>;
  setSelecteds: React.Dispatch<React.SetStateAction<string[]>>;
  selecteds: string[];
  setInstructions: React.Dispatch<React.SetStateAction<string>>;
  setLoadingIA: React.Dispatch<React.SetStateAction<boolean>>;
  onFinish: ({
    currentProducts,
  }: {
    currentProducts: IProductToTable[];
  }) => Promise<void>;
  setIAMode: React.Dispatch<React.SetStateAction<boolean>>;
  setReviewMode: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
  const [inputValue, setInputValue] = useState("");
  const [activeInstructions, setActiveInstructions] = useState(false);
  const [inputArea, setInputArea] = useState("");

  const { template, products } = useProductContext();
  const items: { title: string; id: string }[] = template.fields.fields
    .filter((item: any) => {
      return item.type !== "file";
    })
    .map((item: any) => {
      return { title: item.title, id: item.id };
    });

  const debouncedInputValue = useDebounce(inputValue, 250);
  const itemsToView = useMemo(() => {
    const list = items.filter((item) => {
      return item.title
        .toLowerCase()
        .includes(debouncedInputValue.toLowerCase());
    });
    return list;
  }, [debouncedInputValue, items]);

  function handleClick({
    currentItem,
  }: {
    currentItem: { title: string; id: string };
  }): void {
    setSelecteds((prev) => {
      if (prev.includes(currentItem.id)) {
        return prev.filter((id) => id !== currentItem.id);
      }
      return [...prev, currentItem.id];
    });
  }

  const all = items.length === selecteds.length;

  useEffect(() => {
    setInstructions(inputArea);
  }, [inputArea, setInstructions]);

  return (
    <WrapperCollectInfomation>
      <Modal isOpen changeVisible={() => ""} width={640}>
        <Header>
          <Title>Coletar informações</Title>
          <ButtonClose onClick={() => setModalInformationOpened(false)}>
            <CloseIcon />
          </ButtonClose>
        </Header>
        <ContentTop>
          <InputButtonWrapper>
            <SearchIcon />
            <DefaultInput
              label=""
              type=""
              value={inputValue}
              changeValue={setInputValue}
              required={false}
              placeHolder="Pesquisar coluna"
              alertTitle=""
              alertContent=""
            />
            <NavigationButton
              abort
              onClick={() => {
                if (!all) {
                  const allIds = items.map((item) => item.id);
                  setSelecteds(allIds);
                } else {
                  setSelecteds([]);
                }
              }}
            >
              {all ? "Limpar seleção" : "Selecionar tudo"}
            </NavigationButton>
          </InputButtonWrapper>
          <SectionTitle>Selecione a coluna desejada</SectionTitle>
          <ContainerItems>
            {itemsToView.map((item) => (
              <ItemButton
                onClick={() => handleClick({ currentItem: item })}
                key={item.id}
                isActive={selecteds.includes(item.id)}
              >
                {item.title}
              </ItemButton>
            ))}
          </ContainerItems>
        </ContentTop>
        <TextSwitchWrapper>
          <Text>Dê instruções para a IA, se preferir</Text>
          <SwitchCustom
            checked={activeInstructions}
            onChange={setActiveInstructions}
          />
        </TextSwitchWrapper>
        {activeInstructions && (
          <TextArea
            onChange={(e) => setInputArea(e.target.value)}
            value={inputArea}
          />
        )}
        <ContainerButton>
          <NavigationButton
            disabled={selecteds.length === 0}
            onClick={() => {
              setLoadingIA(true);
              setReviewMode(true);
              setModalInformationOpened(false);
              setIAMode(false);
              onFinish({ currentProducts: products });
            }}
          >
            Aplicar IA
          </NavigationButton>
        </ContainerButton>
      </Modal>
    </WrapperCollectInfomation>
  );
}

export default CollectInformation;
