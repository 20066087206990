// eslint-disable-next-line import/no-cycle
import { useIAContext } from "../../../context/IAContext";
import { ContainerButtons, ContainerModal, Text } from "./styles";
import { NavigationButton } from "../../NavigationButton/styles";

// import { Container } from './styles';

function ReviewModal(): JSX.Element {
  const { finishEnrichment } = useIAContext();
  return (
    <ContainerModal>
      <div>
        <Text>Revise suas descrições.</Text>
      </div>
      <ContainerButtons>
        <NavigationButton onClick={finishEnrichment}>
          Finalizar revisão
        </NavigationButton>
      </ContainerButtons>
    </ContainerModal>
  );
}

export default ReviewModal;
