import React, { useCallback } from "react";
// eslint-disable-next-line import/no-cycle
import { ContainerButtons, ContainerModal, Text } from "./styles";
import DefaultInput from "../../DefaultInput";
import { NavigationButton } from "../../NavigationButton/styles";
import { ReactComponent as StarsIcon } from "../MenuIA/svgs/stars.svg";
// import { Container } from './styles';

function IAModalSelectItems({
  convertData,
  setTextSelectedToIa,
  textSelectedToIa,
  setIAMode,
  setModalInformationOpened,
}: {
  convertData({ data }: { data: string | number[] }): void;
  setTextSelectedToIa: React.Dispatch<React.SetStateAction<string>>;
  textSelectedToIa: string;
  setIAMode: React.Dispatch<React.SetStateAction<boolean>>;
  setModalInformationOpened: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element {
  // const [currentValue, setCurrentValue] = useState("");

  const handleChangeValue = useCallback(
    (value: string): void => {
      convertData({ data: value });
      setTextSelectedToIa(value);
    },
    [convertData, setTextSelectedToIa],
  );

  const onCancel = useCallback((): void => {
    setTextSelectedToIa("");
    convertData({ data: "" });
    setIAMode(false);
  }, [setTextSelectedToIa, convertData, setIAMode]);

  return (
    <ContainerModal>
      <div>
        <Text>Selec. ou digite o número da(s) linha(s)</Text>
        <DefaultInput
          label=""
          type=""
          value={textSelectedToIa}
          changeValue={handleChangeValue}
          required={false}
          placeHolder=""
          alertTitle=""
          alertContent=""
        />
      </div>
      <ContainerButtons>
        <NavigationButton abort onClick={onCancel}>
          Cancelar
        </NavigationButton>
        <NavigationButton
          onClick={() => {
            setModalInformationOpened(true);
          }}
          disabled={!textSelectedToIa}
        >
          <StarsIcon />
          Gerar descrições
        </NavigationButton>
      </ContainerButtons>
    </ContainerModal>
  );
}

export default IAModalSelectItems;
