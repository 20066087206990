import { ICol } from "../components/CustomTable/CustomTable";
import { IHeaderTable } from "../context/products/product.context";

function addGeneratedDescriptionColumn(list: string[]): string[] {
  const updatedList: string[] = [];

  list.forEach((item) => {
    updatedList.push(item);
    if (item === "Descrição") {
      updatedList.push("Descrição gerada por IA");
    }
  });

  return updatedList;
}

const addDescriptionIACols = (list: ICol[]): ICol[] => {
  const descriptionIA = {
    title: "DescriçãoIA",
    data: "000000",
    className: "htLeft htMiddle",
    type: "ia",
    required: false,
    options: [""],
    hidden: false,
    frozen: false,
    width: "640px",
    order: "-1",
  } as unknown as ICol;

  const updatedList: ICol[] = [];

  list.forEach((item) => {
    updatedList.push(item);
    if (item.title === "Descrição") {
      updatedList.push(descriptionIA);
    }
  });

  return updatedList;
};

const addDescriptionIAHeaderTable = (list: IHeaderTable[]): IHeaderTable[] => {
  const descriptionIA = {
    title: "DescriçãoIA",
    data: "000000",
    className: "htLeft htMiddle",
    type: "ia",
    required: false,
    options: [""],
    hidden: false,
    frozen: false,
    width: "640px",
    order: "-1",
  } as IHeaderTable;

  const updatedList: IHeaderTable[] = [];

  list.forEach((item) => {
    updatedList.push(item);
    if (item.title === "Descrição") {
      updatedList.push(descriptionIA);
    }
  });

  return updatedList;
};

export { addDescriptionIACols, addDescriptionIAHeaderTable };

export default addGeneratedDescriptionColumn;
