import React, { useState } from "react";
import {
  Bar,
  ButtonClose,
  ContainerButton,
  ContainerButtonError,
  ContainerButtonModal,
  ContainerContent,
  ContainerModalIA,
  Header,
  LoadingBar,
  Quest,
  Text,
  TextError,
  TextModal,
  Title,
  TitleButton,
} from "./styles";
import { NavigationButton } from "../../NavigationButton/styles";
import { ReactComponent as ErrorIcon } from "../MenuIA/svgs/erroricon.svg";
import Modal from "../../Modal";
import { ReactComponent as CloseIcon } from "../../../assets/close-gray.svg";

const error = false;

function LoadingIA({
  progress,
  total,
  current,
  handleDisconnect,
}: {
  progress: number;
  total: number | null;
  current: number | null;
  handleDisconnect: () => void;
}): JSX.Element {
  const [opened, setOpened] = useState(true);
  const [openedModal, setOpenedModal] = useState(false);

  return (
    <ContainerModalIA errorMode={error && !opened}>
      <TitleButton onClick={() => setOpened(!opened)}>
        {error && <ErrorIcon />} Aplicando IA...
      </TitleButton>
      {opened && (
        <>
          <ContainerContent>
            <Text>
              {total! + 1 - current!} de {total! + 1} produtos
            </Text>
            <LoadingBar>
              <Bar progress={`${progress}%`} />
            </LoadingBar>
          </ContainerContent>
          <ContainerButton>
            <NavigationButton prev onClick={() => setOpenedModal(true)}>
              Interromper aplicação
            </NavigationButton>
          </ContainerButton>
        </>
      )}
      {/* {error && opened && (
        <>
          <hr />
          <TitleButton onClick={() => setOpened(!opened)}>
            Erro ao aplicar IA
          </TitleButton>
          <TextError>
            Aguarde finalizar o carregamento para gerar novo
          </TextError>
          <ContainerContent red>
            <TextError red>
              As linhas 2-91, 96, 104, 203 falharam ao aplicar IA
            </TextError>
          </ContainerContent>
          <ContainerButtonError>
            <NavigationButton>Gerar novamente</NavigationButton>
          </ContainerButtonError>
        </>
      )} */}
      <Modal isOpen={openedModal} changeVisible={() => ""} width={600}>
        <Header>
          <Title>Interromper aplicação</Title>
          <ButtonClose onClick={() => setOpenedModal(false)}>
            <CloseIcon />
          </ButtonClose>
        </Header>
        <TextModal>
          Ao interromper o carregamento,não será possível reverter a ação.
        </TextModal>
        <Quest>Você tem certeza que deseja parar?</Quest>
        <ContainerButtonModal>
          <NavigationButton abort onClick={() => setOpenedModal(false)}>
            Cancelar
          </NavigationButton>
          <NavigationButton prev onClick={() => handleDisconnect()}>
            Interromper aplicação
          </NavigationButton>
        </ContainerButtonModal>
      </Modal>
    </ContainerModalIA>
  );
}

export default LoadingIA;
